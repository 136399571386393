import { render, staticRenderFns } from "./RegistrationSteps.vue?vue&type=template&id=2f1ae3e4&"
import script from "./RegistrationSteps.vue?vue&type=script&lang=js&"
export * from "./RegistrationSteps.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationSteps.vue?vue&type=style&index=0&id=2f1ae3e4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/var/www/ingotbrokers-website/components/SectionTitle.vue').default})
